<template>
    <PageLayout>
        <template v-slot:side>
            <v-icon class="mb-5" color="secondary" @click="filter_show===true ? filter_show=false : filter_show=true">mdi-filter-plus</v-icon>
            <v-list class="filter_left">
                <v-list-group :ripple="false" v-model="group1" :group="'group1'" class="px-0">
                    <template v-slot:activator class="px-0">
                        <v-list-item-title :ripple="false" class="main_filter">{{$t('system_filters')}}</v-list-item-title>
                    </template>

                    <v-list-item :ripple="false" v-for="(item, i) in system_filters" :key="i" link :class="item.id === invoice_status ? 'active' : ''">
                        <v-list-item-title class="cursor-pointer" @click="sendRoute(item)" v-text="item.name"></v-list-item-title>
                        <v-list-item-icon v-text="'-'" class="filter_count"></v-list-item-icon>
                    </v-list-item>
                </v-list-group>
            </v-list>

            <v-list class="filter_left" v-if="Additional.length > 0">
                <v-list-group :ripple="false" v-model="group2" :group="'group2'" class="px-0">
                    <template v-slot:activator class="px-0">
                        <v-list-item-title :ripple="false" class="main_filter">{{$t('additional')}}</v-list-item-title>
                    </template>
                    <v-list-item :ripple="false" v-for="(item, i) in Additional" :key="i" link>
                        <v-list-item-title  v-text="item.title" ></v-list-item-title>
                        <v-list-item-icon v-text="item.count" class="filter_count"></v-list-item-icon>
                    </v-list-item>
                </v-list-group>
            </v-list>
        </template>
        <template>
            <v-form @submit.prevent="getInvoices('search')">
                <v-btn v-show="$vuetify.breakpoint.xsOnly" :to="{name: 'invoice.create'}" color="secondary" fab fixed bottom right class="v-btn--add-form-top white--text">
                    <v-icon>mdi-plus</v-icon>
                </v-btn>
                <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                    <div>
                        <div class="py-0">
                            <v-row>
                                <v-col cols="12" sm="10">
                                    <h1 class="mb-10">{{ filter_show ? $t('search_invoice') : $t('invoice') }}</h1>
                                </v-col>
                                <v-col v-if="!$vuetify.breakpoint.xsOnly" cols="12" sm="2" v-show="!filter_show" class="text-right">
                                    <v-btn :to="{name: 'invoice.create'}"  class="infinity_button" color="secondary">
                                        {{$t('create') }}
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-row v-if="filter_show">
                                <v-col class="py-0" cols="12" sm="6">
                                    <ValidationProvider ref="company" rules="min:1" v-slot="{ errors, valid }">
                                        <v-autocomplete v-model="company" :items="companyItems"
                                                        :error-messages="errors" :error="!valid"
                                                        :search-input.sync="companySearching"
                                                        item-text="name" item-value="id"
                                                        :loading="loadingCompanies"
                                                        :no-data-text="companySearching ? $t('nothing_found_by',{'search': companySearching}) : $t('nothing_found_name')"
                                                        :label="$t('company')"
                                                        @click:clear="companyItems=[]"
                                                        @click="clearCompanies"
                                                        @click:append="redirectToCompany(company)"
                                                        autocomplete="off"

                                                        outlined
                                                        solo
                                                        flat
                                                        dense
                                                        :disabled="loading"
                                                        :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                                        color="primary"
                                                        class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                        clearable
                                        >
                                            <template v-slot:item="{ attrs, item, parent, selected }">
                                                        <span class="search-list">
                                                            <span v-html="item.name"></span>
                                                            <span class="ml-2 hidden-is-empty"
                                                                  v-text="item.bin_iin"></span>
                                                        </span>
                                            </template>
                                            <template v-slot:selection="{ attrs, item, parent, selected }">
                                                        <span class="search-list">
                                                            <span v-html="item.name"></span>
                                                            <span class="ml-2 hidden-is-empty"
                                                                  v-text="item.bin_iin"></span>
                                                        </span>
                                            </template>
                                        </v-autocomplete>
                                    </ValidationProvider>
                                </v-col>
                                <v-col class="py-0" cols="12" sm="3">
                                    <v-dialog ref="dateDialog" v-model="dateDialog" width="290px">
                                        <v-date-picker ref="pickerDateContract" v-model="date"
                                                       :locale="lang"
                                                       @change="dateDialog = false">
                                        </v-date-picker>
                                    </v-dialog>
                                    <ValidationProvider ref="dateContract" rules="date_format"
                                                        v-slot="{ errors, valid }">
                                        <v-text-field v-model="date" :error-messages="errors"
                                                      :error="!valid"
                                                      :label="$t('date_created')"
                                                      readonly
                                                      @click.stop="dateDialog = !dateDialog"
                                                      @click:clear="date = null"
                                                      outlined
                                                      solo
                                                      flat
                                                      dense
                                                      :disabled="loading"
                                                      :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                                      color="primary"
                                                      class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                      clearable

                                        ></v-text-field>
                                    </ValidationProvider>
                                </v-col>
                                <v-col class="py-0" cols="12" sm="3">
                                    <ValidationProvider ref="invoice_status" rules=""
                                                        v-slot="{ errors, valid }">
                                        <v-select v-model="invoice_status"
                                                  :items="invoice_statusItems" :error-messages="errors"
                                                  :error="!valid"
                                                  item-text="name" item-value="id"
                                                  :label="$t('invoice_status')"
                                                  outlined
                                                  solo
                                                  flat
                                                  dense
                                                  :disabled="loading"
                                                  :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                                  color="primary"
                                                  class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                  clearable
                                        ></v-select>
                                    </ValidationProvider>
                                </v-col>
                            </v-row>
                        </div>
                        <div v-if="filter_show"  class="px-4 py-4">
                            <v-row>
                                <v-col cols="12" sm="3" md="2">
                                    <v-select :value="perPage" :items="perPageItems"
                                              :label="$t('items_per_page')"
                                              @input="perPage = options.itemsPerPage = Number($event)" hide-details

                                              outlined
                                              solo
                                              flat
                                              dense
                                              :disabled="loading"
                                              :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                              color="primary"
                                              class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"

                                    ></v-select>
                                </v-col>
                                <v-col cols="12" sm="9" md="10"
                                       class="d-flex justify-center justify-sm-end align-self-center">
                                    <v-btn type="submit" :disabled="invalid || loading" :block="$vuetify.breakpoint.xsOnly" color="primary" class="infinity_button">{{ $t('search') }}</v-btn>
                                </v-col>
                            </v-row>
                        </div>
                    </div>
                </ValidationObserver>
            </v-form>

            <ResizableTable
                class="main-data-table"
                show-select
                :rows="invoices"
                :columns="filteredHeaders.map((item) => {
                    return {
                        ...item,
                        prop: item.value,
                        label: item.text,
                        width: 'auto'
                    }
                })"
                @select="selected = $event"
            >
                <template v-slot:header-select="{ item, isSelected, select }">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon color="primary"
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="openDialogDealToInvoice"
                            >mdi-briefcase-variant</v-icon>
                        </template>
                        <span>{{ $t('assign_new_client') }}</span>
                    </v-tooltip>
                </template>

                <template v-slot:amount="{ item }">
                    <div>
                        <v-btn text plain :to="'/invoice/' + item.id + '/edit'"  class="cursor-pointer font_weight_600 button_link"  >
                            {{ item.amount }}
                            {{ item.deleted ? ' (' + $t('deleted') + ')' : '' }}
                        </v-btn>
                        <v-icon v-if="!item.deleted" @click="deleteInvoice(item)" small>mdi-delete-outline</v-icon>
                    </div>
                </template>

                <template v-slot:date="{ item }">
                    <div>{{ $moment(item.date).format('DD.MM.YYYY') }}</div>
                </template>

                <template v-slot:number_deal="{ item }">
                    <div v-if="item.number_deal">
                        <v-btn text plain :to="'/deal/' + item.deal_uuid + '/show'"  class="cursor-pointer button_link"  >
                            {{ item.number_deal }}
                        </v-btn>
                    </div>
                    <div v-else>{{ $t('no') }}</div>
                </template>

                <template v-slot:invoice_status="{ item }">
                    <div class="cursor-pointer" @click="openDialogInvoiceStatus(item)">{{ item.invoice_status }}</div>
                </template>

                <template v-slot:company.name="{ item }">
                    <div>
                        <v-list subheader two-line class="background_none py-0">
                            <v-list-item class="px-0 py-0 my-0 min_height_auto">
                                <v-list-item-avatar size="36" class="border_blue my-0 py-0 cursor_pointer" @click="showCompany(item.company.uuid)">
                                    <img v-if="item.company.photo !== '/img/avatar.png'" :src="item.company.photo" :alt="item.company.name">
                                    <v-icon v-else class="font-primary" size="36">
                                        ${{'settingsIcon'}}
                                    </v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content class="py-0 title_subtitle">
                                    <v-list-item-title :title="item.company.name">
                                        <v-btn text plain :to="'/company/' + item.company.uuid + '/show'"  class="cursor-pointer button_link"  >
                                            {{ item.company.name }}
                                        </v-btn>
                                    </v-list-item-title>
                                    <v-list-item-subtitle v-text="formatPhoneNumber(item.company.phone)"></v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </div>
                </template>
                <template v-slot:admin.name="{ item }">
                    <div>
                        <v-list subheader two-line class="background_none py-0">
                            <v-list-item class="px-0 py-0 my-0 min_height_auto">
                                <v-list-item-avatar size="36" class="border_blue my-0 py-0">
                                    <img v-if="item.admin.photo !== '/img/avatar.png'" :src="item.admin.photo" :alt="item.admin.name">
                                    <v-icon v-else color="primary" size="22">
                                        ${{'settingsIcon'}}
                                    </v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content class="py-0 title_subtitle">
                                    <v-list-item-title v-text="item.admin.name"></v-list-item-title>
                                    <v-list-item-subtitle v-text="formatPhoneNumber(item.admin.phone)"></v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </div>
                </template>
                <template v-slot:action="{ item }">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn v-on="on" @click="openDialogInvoiceStatus(item)" icon>
                                <v-icon>mdi-cash-check</v-icon>
                            </v-btn>
                        </template>
                        <span>{{$t('payment_confirmation')}}</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn v-on="on" @click="editInvoice(item)" icon>
                                <v-icon>mdi-square-edit-outline</v-icon>
                            </v-btn>
                        </template>
                        <span>{{$t('edit')}}</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn v-on="on" :disabled="item.deleted" @click="deleteInvoice(item)" icon>
                                <v-icon>mdi-delete-outline</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ item.deleted ? $t('remotely') : $t('delete') }}</span>
                    </v-tooltip>
                </template>
            </ResizableTable>
            <v-pagination v-model="page" :total-visible="totalVisiblePag" class="pagination_table" :length="pageCount" :disabled="loading"></v-pagination>

            <v-dialog
                transition="dialog-bottom-transition"
                max-width="600"
                v-model="dialogDeal"
                class="dialog_body"
            >
                <v-card>
                    <v-toolbar class="dialog_toolbar" elevation="0">
                        <div class="ma-auto ml-0">
                            <span>{{ $t('add_deal') }}</span>
                        </div>
                        <div>
                            <v-icon @click="closeDealToInvoice()" :title="$t('close')">mdi-close</v-icon>
                        </div>
                    </v-toolbar>
                    <v-card-text class="pa-2 pb-0">
                        <v-col cols="12">
                            <ValidationProvider ref="company" rules="min:1"
                                                v-slot="{ errors, valid }">
                                <v-autocomplete v-model="deal" :items="dealItems"
                                                :error-messages="errors" :error="!valid"
                                                :search-input.sync="dealSearching"
                                                item-text="number" item-value="id"

                                                append-icon="mdi-location-enter"
                                                :loading="loadingDeals" :disabled="loading"
                                                :no-data-text="dealSearching ? $t('nothing_found_by',{'search': dealSearching}) : $t('nothing_found_name')"
                                                :label="$t('deal.self')"
                                                @click:clear="dealItems=[]"
                                                @click="clearDeals"
                                                clearable autocomplete="off"
                                                outlined
                                                solo
                                                flat
                                                dense
                                                hide-details
                                                :height="$vuetify.breakpoint.xsOnly ? 45 : 31"
                                                color="primary"
                                                class="ma-0 pa-0 my-0 py-0 elevation-0 input_text"
                                >
                                    <template v-slot:item="{ attrs, item, parent, selected }">
                                        <span class="search-list">
                                            <span v-html="item.number"></span>
                                            <span class="ml-2 hidden-is-empty"
                                                  v-text="item.admin.name"></span>
                                        </span>
                                    </template>
                                    <template v-slot:selection="{ attrs, item, parent, selected }">
                                        <span class="search-list">
                                            <span v-html="item.number"></span>
                                            <span class="ml-2 hidden-is-empty"
                                                  v-text="item.admin.name"></span>
                                        </span>
                                    </template>
                                </v-autocomplete>
                            </ValidationProvider>
                        </v-col>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="primary"
                            text
                            @click="addDealToInvoice()"
                        >{{ $t('add') }}</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog
                transition="dialog-bottom-transition"
                max-width="600"
                v-model="dialogInvoiceStatus"
                class="dialog_body"
            >
                <v-card>
                    <v-toolbar class="dialog_toolbar" elevation="0">
                        <div class="ma-auto ml-0">
                            <span>{{ $t('invoice_editing') }}</span>
                        </div>
                        <div>
                            <v-icon @click="closeInvoiceStatus()" :title="$t('close')">mdi-close</v-icon>
                        </div>
                    </v-toolbar>
                    <v-card-text class="pa-2 pb-0">
                        <v-col cols="12">
                            <ValidationProvider ref="invoice_status_edit" rules="required"
                                                v-slot="{ errors, valid }">
                                <v-select v-model="invoice_status_edit" :disabled="loading"
                                          :items="invoiceStatusEditItems" :error-messages="errors"
                                          :error="!valid"
                                          item-text="name" item-value="id"
                                          :label="$t('invoice_status')"
                                          outlined
                                          solo
                                          flat
                                          dense
                                          hide-details
                                          :height="$vuetify.breakpoint.xsOnly ? 45 : 31"
                                          color="primary"
                                          class="ma-0 pa-0 my-0 py-0 elevation-0 input_text"
                                ></v-select>
                            </ValidationProvider>
                        </v-col>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            text
                            @click="paymentConfirmation()"
                        >{{ $t('edit') }}</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </template>
    </PageLayout>
</template>
<script>

    import {mapGetters} from "vuex"
    import {ValidationObserver, ValidationProvider} from 'vee-validate'
    import debounce from "lodash/debounce";
    import FilterView from "@/plugins/mixins/FilterView";

    export default {
        name: "Invoices",
        components: {
            ValidationProvider,
            ValidationObserver
        },
        data() {
            return {
                invoice_status_edit: null,
                invoiceStatusEditItems: [],
                dialogInvoiceStatus:false,
                singleSelect: false,
                selected: [],
                disabledCount: 0,
                deal: null,
                dealItems: [],
                dealSearching: null,
                loadingDeals: false,
                dialogDeal: false,

                date: null,
                dateDialog: false,
                company: null,
                companyItems: [],
                companySearching: null,
                loadingCompanies: false,
                loadingClients: false,
                invoice_status: null,
                invoice_statusItems: [],
                fab: false,
                invoice: null,
                invoices: [],
                invoiceItems: [],
                sortBy: "id",
                sortDir: false,
                page: 1,
                pageCount: 0,
                perPage: 5,
                totalVisiblePag: 7,
                totalInvoices: 0,
                options: {
                    ...FilterView.data().options,
                    sortBy: ['id'],
                },
                loading: false,
                invoice_id: null,
                headers: [
                    {
                        active: true,
                        text: this.$t('amount'),
                        align: "left",
                        sortable: true,
                        value: "amount"
                    },
                    {
                        active: true,
                        text: this.$t('number_deal'),
                        align: "left",
                        sortable: true,
                        value: "number_deal"
                    },
                    {
                        active: true,
                        text: this.$t('invoice_status'),
                        align: "left",
                        sortable: true,
                        value: "invoice_status"
                    },
                    {
                        active: true,
                        text: this.$t('date'),
                        align: "left",
                        sortable: true,
                        value: "date"
                    },
                    {
                        active: true,
                        text: this.$t('uved.self'),
                        align: "left",
                        sortable: true,
                        value: "uved"
                    },
                    {
                        active: true,
                        text: this.$t('manager'),
                        align: "left",
                        sortable: true,
                        value: "admin.name"
                    }
                ],
                system_filters: [
                    {'title': 'Все контакты', 'count': '10'},
                    {'title': 'Мои клиенты', 'count': '0'},
                    {'title': 'Избранное', 'count': '7'},
                ],
                Additional: [],
                group1: true,
                group2: true,
                filter_show: false,
            };
        },
        computed: {
            ...mapGetters(['lang', 'itemsPerPage', 'perPageItems']),
            filteredHeaders() {
                return this.headers.filter(h => !h.hide)
            },
        },
        mounted() {
            this.options.itemsPerPage = this.perPage = this.itemsPerPage
            this.getInvoiceStatuses()
            this.getInvoices()
        },
        watch: {
            options: {
                handler() {
                    this.getInvoices()
                },
                deep: false
            },
            companySearching: debounce(function (val) {
                if (val && !this.company) {
                    this.getCompanies(val)
                }
            }, 500),
            dealSearching: debounce(function (val) {
                if (val) {
                    this.getDeals(val)
                }
            }, 500),
        },
        methods: {
            rowClass(){
                return (!this.$vuetify.breakpoint.xsOnly) ? 'rowClass Table7' : 'rowMobiClass';
            },
            sendRoute(item){
                this.invoice_status = item.id;
                this.getInvoices();
            },
            selectAllToggle(props) {
                if(this.selected.length != this.invoices.length - this.disabledCount) {
                    this.selected = [];
                    const self = this;
                    props.items.forEach(item => {
                        if(!item.number_deal) {
                            self.selected.push(item);
                        }
                    });
                } else this.selected = [];
            },

            async getDeals(str) {
                this.loadingDeal = true
                let params = {};
                //params.filter = 'not_invoice'
                if (str !== 'undefined') {
                    params.number = str;
                }

                await this.$http
                    .get("admin/deal", {
                        params: params,
                    })
                    .then(res => {
                        this.dealItems = res.body.data;
                    })
                    .catch(err => {
                        this.dealItems = []
                        this.$toastr.error(this.$t('failed_to_get_deals'))
                    })
                    .finally(end => {
                        this.loadingDeal = false
                    })
            },
            clearDeals() {
                if (!this.deal) {
                    this.dealItems = []
                }
            },
            closeDealToInvoice(){
                this.dialogDeal  = false
                this.deal = null
                this.dealItems = []
                this.dealSearching = null

            },
            async addDealToInvoice(){
                var _this = this;
                this.progress = 0
                this.loading = true
                var formData = new FormData()
                this.selected.forEach(function callback(item, key) {
                    formData.append(`invoices_uuid[${key}]`, item.uuid)
                });

                if (this.deal ) {
                    formData.append('deal', this.deal )
                }

                // Add
                await this.$http
                    .post('admin/invoice/deal', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('deal_has_been_updated'))
                        this.closeDealToInvoice();
                        this.getInvoices();
                        this.selected = [];
                    })
                    .catch(err => {
                        this.closeDealToInvoice();
                        this.$toastr.error(this.$t('deal_has_not_been_updated'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })


            },
            openDialogDealToInvoice(){
                this.deal = null
                this.dealItems =  []
                this.dealSearching = null
                this.dialogDeal   = true
            },

            closeInvoiceStatus(){
                this.dialogInvoiceStatus  = false
                this.invoice_status_edit = null
                this.invoice_id = null
            },
            openDialogInvoiceStatus(item){
                this.invoice_id = item.id
                this.invoice_status_edit = item.invoice_status_id
                this.dialogInvoiceStatus  = true
            },
            async paymentConfirmation() {

                var _this = this;
                this.progress = 0
                this.loading = true
                var formData = new FormData()

                if (this.invoice_id) {
                    formData.append('invoice_id', this.invoice_id)
                }
                if (this.invoice_status_edit) {
                    formData.append('invoice_status_edit', this.invoice_status_edit)
                }


                await this.$http
                    .put(`admin/invoice/${this.invoice_id}/status`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('invoice_has_been_updated'))
                        this.closeInvoiceStatus();
                        this.getInvoices();
                        //this.forceRerender()
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('invoice_has_not_been_updated'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        if(prop ==='answer_scanned' || prop ==='request_scanned'){
                                            this.$toastr.error(err.body.errors[prop][0])
                                        }
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            },
            editInvoice(item) {
                this.$router.push({
                    name: 'invoice.edit',
                    params: {
                        id: item.id
                    }
                })
            },
            async deleteInvoice(item) {
                if (confirm(this.$t('delete_invoice'))) {
                    var _this = this
                    this.loading = true;
                    await this.$http
                        .delete(`admin/invoice/${item.id}`)
                        .then(res => {
                            this.$toastr.success(this.$t('invoice_has_been_deleted'))
                            this.getInvoices()
                        })
                        .catch(err => {
                            this.$toastr.error(this.$t('invoice_has_not_been_deleted'))
                            if (err && err.body && err.body.message) {
                                for (let prop in err.body.errors) {
                                    if (hasOwnProperty.call(err.body.errors, prop)) {
                                        if (_this.$refs[prop]) {
                                            _this.$refs[prop].setErrors([
                                                err.body.errors[prop][0]
                                            ])
                                        }
                                    }
                                }
                                if (!err.body.errors) {
                                    this.$toastr.error(err.body.message)
                                }
                            }
                        })
                        .finally(end => {
                            this.loading = false
                        })
                }
            },
            async getInvoices(type) {
                this.loading = true;
                const {
                    sortBy,
                    sortDir: sortDesc,
                    page,
                    itemsPerPage
                } = this.options;
                let params = {};
                if (sortBy[0] !== undefined) {
                    params.sortBy = sortBy[0];
                }
                if (sortDesc !== undefined) {
                    params.sortDir = sortDesc ? 'asc' : 'desc';
                }
                if (page !== undefined) {
                    if (type === 'search') {
                        params.page = 1
                    } else {
                        params.page = page
                    }
                }
                if (itemsPerPage !== undefined) {
                    params.perPage = itemsPerPage;
                }
                if (this.invoice) {
                    params.invoice = this.invoice;
                }

                if (this.date) {
                    params.date = this.date;
                }

                if (this.invoice_status) {
                    params.invoice_status = this.invoice_status;
                }



                if (this.company) {
                    if (this.company.id) {
                        params.company = this.company.id
                    } else {
                        params.company = this.company
                    }
                }


                await this.$http
                    .get("admin/invoice", {
                        params: params,
                    })
                    .then(res => {
                        this.invoices = res.body.data
                        this.page = res.body.meta.current_page
                        this.totalInvoices = res.body.meta.total
                        this.pageCount = res.body.meta.last_page
                        this.invoices.map(item => {
                            if (item.number_deal !== ''){
                                this.disabledCount = this.disabledCount + 1
                            }
                        })
                    })
                    .catch(err => {
                        this.invoices = []
                        this.totalInvoices = 0
                        this.$toastr.error(this.$t('failed_to_get_list_invoices'))
                    })
                    .finally(end => {
                        this.loading = false
                    })
            },
            clearCompanies() {
                if (!this.company) {
                    this.companyItems = []
                }
            },
            redirectToCompany(val) {
                if (val) {
                    this.$router.push({
                        name: 'company.profile',
                        params: {
                            id: val
                        }
                    })
                }
            },
            async getCompanies(str) {
                if (str) {
                    this.loadingCompanies = true
                    let params = {}
                    params.filter = 'search'
                    if (str !== 'undefined') {
                        params.company = str
                    }
                    await this.$http
                        .get("admin/company", {
                            params: params,
                        })
                        .then(res => {
                            this.companyItems = res.body.data
                        })
                        .catch(err => {
                            this.companyItems = []
                        })
                        .finally(end => {
                            this.loadingCompanies = false
                        })
                }
            },
            async getInvoiceStatuses() {
                let params = {};
                params.filter = 'all';
                await this.$http
                    .get(`admin/invoice_status`, {
                        params: params,
                    })
                    .then(res => {
                        this.invoice_statusItems = res.body.data
                        this.invoiceStatusEditItems = res.body.data
                        this.system_filters = res.body.data
                    })
                    .catch(err => {
                        this.invoice_statusItems = [];
                        this.invoiceStatusEditItems = [];
                        this.$toastr.error(this.$t('failed_to_get_list_invoice_statuses'))
                    });
            },
            showDeal(uuid) {
                this.$router.push({
                    name: 'deal.show',
                    params: {
                        id: uuid
                    }
                })
            },
            showCompany(uuid) {
                this.$router.push({
                    name: 'company.show',
                    params: {
                        id: uuid
                    }
                })
            },
        }
    }
</script>
